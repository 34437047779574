import { Form } from "@remix-run/react";
import clsx from "clsx";
import { FormEventHandler } from "react";
import { ClientSide } from "~/@types";
import { IInArticleNewsletterFields } from "~/@types/generated/contentful";
import { TextCta } from "~/components/ctas";
import { Arrow } from "~/components/icons";
import { useNewsletter } from "~/contexts/NewsletterProvider";

interface NewsletterFormProps {
  fields: ClientSide<IInArticleNewsletterFields>;
}

export default function InArticleNewsletter({ fields }: NewsletterFormProps) {
  const { submitNewsletter, submitted } = useNewsletter();

  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    submitNewsletter();
  };

  if (submitted) {
    return (
      <div className="col-span-full mt-8 flex h-[415px] items-center justify-center bg-black py-10 px-4 text-white lg:col-span-6 lg:col-start-4 lg:h-[352px] lg:p-10">
        <h5 className="text-center">Thank you for subscribing!</h5>
      </div>
    );
  }

  return (
    <Form
      className="col-span-full m-auto mt-8 bg-black py-10 px-4 text-white lg:col-span-6 lg:col-start-4 lg:p-10"
      onSubmit={handleSubmit}
      id="newsletter_form"
    >
      <h5 className="mb-[22px] lg:mb-10">{fields.heading}</h5>
      <div className="flex flex-col lg:flex-row lg:gap-4">
        <label htmlFor="newsletter_first_name" className="flex-1">
          <input
            id="newsletter_first_name"
            name="newsletter_first_name"
            type={"text"}
            required
            title="Please enter at least 2 letters."
            placeholder="First name *"
            className={clsx(
              "body border-b-1 col-span-4 w-full border-0 border-b border-solid bg-black py-1 outline-0 outline-offset-4 outline-slate placeholder:text-white focus:outline-1 lg:col-start-2"
            )}
          />
          <span className="meta-small text-white opacity-0 transition-opacity peer-focus:opacity-100">
            First Name *
          </span>
        </label>
        <label htmlFor="newsletter_last_name" className="flex-1">
          <input
            id="newsletter_last_name"
            name="newsletter_last_name"
            type={"text"}
            title="Please enter at least 2 letters."
            required
            placeholder="Last name *"
            className={clsx(
              "body border-b-1 col-span-4 w-full border-0 border-b border-solid bg-black py-1 outline-0 outline-offset-4 outline-slate placeholder:text-white focus:outline-1 lg:col-start-2"
            )}
          />
          <span className="meta-small text-white opacity-0 transition-opacity peer-focus:opacity-100">
            Last Name *
          </span>
        </label>
      </div>
      <input
        type={"email"}
        id="newsletter_email"
        name="newsletter_email"
        className="body border-b-1 col-span-4 w-full border-0 border-b border-solid border-b-white bg-black py-1 outline-0 outline-offset-4 outline-slate placeholder:text-white focus:outline-1 lg:col-start-2"
        placeholder="Email *"
        required
      />
      <div className="my-10 flex items-start">
        <input
          type={"checkbox"}
          id="newsletter_consent"
          name="newsletter_consent"
          className="mr-3 border border-solid border-black"
          checked
          readOnly
          hidden
        />
        <label
          htmlFor="newsletter_consent"
          className="aeonik-base text-[10px] text-dark_grey"
        >
          By signing up, you agree to receive emails about the work of Tony
          Blair and the Tony Blair Institute. For full information on the use of
          your data please see our{" "}
          <a
            target="__blank"
            href={fields.privacyPolicyUrl}
            className="underline"
          >
            privacy policy
          </a>
          .
        </label>
      </div>
      <select
        id="newsletter_contact_type"
        name="newsletter_contact_type"
        className="hidden"
        required
      >
        <option>public</option>
      </select>
      <input
        type="hidden"
        name="page_signed_up_on"
        value={typeof window !== "undefined" ? window.location.href : ""}
      />
      <TextCta
        id="newsletter-button"
        className="focus:outline-white"
        kind={"white"}
        icon={<Arrow />}
        label={fields.buttonText ?? "Sign Up"}
        type="submit"
      />
    </Form>
  );
}
