import { Dialog, Transition } from "@headlessui/react";
import clsx from "clsx";
import {
  createContext,
  useContext,
  useState,
  // Fragment,
  ReactNode,
} from "react";
import { CloseCta, TextCta } from "~/components/ctas";
import { Arrow } from "~/components/icons";
import { NewsletterForm } from "~/components/shared";
import { useNavShowing } from "./NavShowingProvider";

interface NewsletterContextType {
  openModal: () => void;
  submitNewsletter: () => void;
  submitted: boolean;
}

const NewsletterContext = createContext<NewsletterContextType | undefined>(
  undefined
);

function NewsletterProvider({ children }: { children: ReactNode }) {
  const { setNavShowing } = useNavShowing();
  const [isOpen, setIsOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  function closeModal() {
    setNavShowing(true);
    setIsOpen(false);
    document.body.style.overflow = "";
  }

  function openModal() {
    setNavShowing(false);
    setIsOpen(true);
    document.body.style.overflow = "hidden";
  }

  function submitNewsletter() {
    setSubmitted(true);
    console.log("Newsletter submitted");
  }

  return (
    <NewsletterContext.Provider
      value={{
        openModal,
        submitNewsletter,
        submitted,
      }}
    >
      <Dialog
        id="dialog"
        as="div"
        className="relative z-10"
        onClose={closeModal}
        open={isOpen}
        unmount={false}
      >
        <Transition show={isOpen} as={"div"} unmount={false}>
          <Transition.Child
            className="fixed inset-0"
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="h-full w-full bg-black bg-opacity-25" />
          </Transition.Child>

          <Transition.Child
            className="lg:tbi-grid flex-center fixed inset-0 my-4 overflow-x-auto lg:my-0"
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="col-span-full col-start-1 grid h-full max-w-[345px] grid-cols-4 grid-rows-[min-content] overflow-x-hidden overflow-y-scroll bg-white text-black lg:col-span-6 lg:col-start-4 lg:h-min lg:max-w-full lg:grid-cols-6">
              <div className="flex-center col-span-1 col-start-4 my-0 aspect-square lg:static lg:col-start-6 lg:my-6">
                <CloseCta
                  kind="black"
                  onClick={closeModal}
                  className="aspect-square"
                />
              </div>
              <div
                className={clsx(
                  "col-span-4 col-start-1 row-start-2 mx-3 lg:col-start-2 lg:mx-0",
                  { hidden: submitted }
                )}
              >
                <NewsletterForm onSubmit={() => submitNewsletter()} />
              </div>
              <Transition
                className={
                  "flex-center col-span-full mb-20 aspect-square flex-col"
                }
                show={submitted}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
              >
                <h3 className={clsx("mb-10 max-w-[410px] text-center")}>
                  Thank you for subscribing!
                </h3>
                <TextCta
                  kind="black-tertiary"
                  label="Go Back Home"
                  icon={<Arrow />}
                  onClick={() => setIsOpen(false)}
                  navLinkProps={{
                    preventScrollReset: true,
                  }}
                />
              </Transition>
            </Dialog.Panel>
          </Transition.Child>
        </Transition>
      </Dialog>
      {children}
    </NewsletterContext.Provider>
  );
}

function useNewsletter() {
  const context = useContext(NewsletterContext);
  if (context === undefined) {
    throw new Error("useNewsletter must be used within a NewsletterProvider");
  }
  return context;
}

export { NewsletterProvider, useNewsletter };
