"use client";

import clsx from "clsx";
import {
  IItemWistiaVideoCardFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import { useRef, useEffect, useState } from "react";
import { WistiaPlayer } from "@wistia/wistia-player-react";

type CardsProps = {
  fields: SpecificLocaleFields<IItemWistiaVideoCardFields>;
  layout?: string;
  reverse?: boolean;
};

export default function WistiaGridCard({
  fields,
  layout,
  reverse,
}: CardsProps) {
  const wistiaPlayerPopup = useRef<any>(null);
  const [isMounted, setIsMounted] = useState(false);

  const videoData = fields.video?.items?.[0];
  const hashedId = videoData?.hashed_id;
  const thumbnailUrl = videoData?.thumbnail?.url;
  const adjustedThumbnailUrl = thumbnailUrl
    ? thumbnailUrl.replace(
        /image_crop_resized=\d+x\d+/g,
        "image_crop_resized=800x450"
      )
    : null;
  const { duration } = videoData;
  const minutes = String(Math.floor(duration / 60)).padStart(2, "0");
  const seconds = String(Math.floor(duration % 60)).padStart(2, "0");
  const timestamp = `${minutes}:${seconds}`;

  useEffect(() => {
    setIsMounted(true);
  }, []); //hydration fix

  const handleReady = () => {
    if (typeof window === "undefined") return;

    const modalContainer = document.getElementById(
      `wistia-player-${hashedId}_popover`
    );
    const closeButton = document.querySelectorAll(
      ".wistia_placebo_close_button"
    );
    if (closeButton) {
      closeButton.forEach((el) => el.classList.add("hidden"));
    }
    if (modalContainer) {
      const customCloseBtn = document.createElement("button");
      customCloseBtn.className =
        "absolute top-2 right-2 lg:top-[20px] lg:right-[20px] z-[1] p-2 lg:p-3 bg-white rounded-full";
      customCloseBtn.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none">
      <path d="M17 1 1 17M1 1l16 16" stroke="#000" stroke-width="2" stroke-linecap="round"/>
    </svg><span class="sr-only">Close popup</span>`;
      customCloseBtn.onclick = () => wistiaPlayerPopup.current?.hidePopover();
      modalContainer.appendChild(customCloseBtn);
    }
  };

  const handleClick = () => {
    if (wistiaPlayerPopup.current === null) {
      return;
    }
    wistiaPlayerPopup.current.showPopover();
    wistiaPlayerPopup.current.play();
  };

  const handlePause = () => {
    if (typeof window === "undefined") return;

    wistiaPlayerPopup.current?.requestControls("keep-controls-visible");
    const modalContainer = document.getElementById(
      `wistia-player-${hashedId}_popover`
    );
    const playBarH =
      modalContainer?.querySelector(".w-bottom-bar")?.clientHeight || 0;

    if (modalContainer) {
      let timestampElement = modalContainer.querySelector(
        ".custom-timestamp"
      ) as HTMLElement;
      if (!timestampElement) {
        timestampElement = document.createElement("span");
        timestampElement.className =
          "custom-timestamp body-small absolute z-[1] rounded-md bg-black/60 px-2 py-1 text-white right-2 bottom-2 md:right-3 md:bottom-3";
        timestampElement.style.marginBottom = `${playBarH}px`;
        modalContainer.appendChild(timestampElement);
      }
      timestampElement.textContent = timestamp;
      let playButton = modalContainer.querySelector(
        `#custom-play-button-${hashedId}`
      ) as HTMLElement;
      if (!playButton) {
        playButton = document.createElement("button");
        playButton.id = `custom-play-button-${hashedId}`;
        playButton.className =
          "absolute z-[1] flex h-11 w-11 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-full bg-white text-black md:h-16 md:w-16 lg:h-24 lg:w-24";
        playButton.innerHTML = `
          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="12" viewBox="0 0 10 12" fill="none" class="lg:hidden" role="img">
            <title>play button</title>
            <path d="M0.863281 1.20195V10.8019C0.863281 11.2219 1.33329 11.4719 1.68329 11.2319L8.90332 6.34195C9.21332 6.13195 9.21332 5.67195 8.90332 5.47195L1.68329 0.761946C1.33329 0.531946 0.873291 0.781947 0.873291 1.20195H0.863281Z" fill="black"/>
          </svg>
          <span class="body-large hidden lg:block">PLAY</span>
        `;
        playButton.style.marginTop = `-${playBarH / 2}px`;
        playButton.onclick = () => wistiaPlayerPopup.current?.play();
        modalContainer.appendChild(playButton);
      }
    }
  };

  const handlePlay = () => {
    if (typeof window === "undefined") return;

    wistiaPlayerPopup.current?.releaseControls("keep-controls-visible");
    const modalContainer = document.getElementById(
      `wistia-player-${hashedId}_popover`
    );

    if (modalContainer) {
      const timestampElement =
        modalContainer.querySelector(".custom-timestamp");
      if (timestampElement) {
        modalContainer.removeChild(timestampElement);
      }
      const playButton = modalContainer.querySelector(
        `#custom-play-button-${hashedId}`
      );
      if (playButton) {
        modalContainer.removeChild(playButton);
      }
    }
  };

  if (!isMounted) {
    return (
      <div
        className={clsx("flex flex-col gap-4", {
          "md:flex-row-reverse md:gap-6": reverse && layout === "50-50",
          "md:flex-row md:gap-6": !reverse && layout === "50-50",
        })}
      >
        <div className="flex-1">
          <div className="relative">
            <img src={adjustedThumbnailUrl} alt={fields.heading} />
            <div className="absolute top-1/2 left-1/2 h-10 w-10 -translate-x-1/2 -translate-y-1/2 rounded-full bg-white md:h-16 md:w-16" />
            <span className="body-small absolute right-2 bottom-2 z-[1] rounded-md bg-black/60 px-2 py-1 text-white md:right-3 md:bottom-3">
              {timestamp}
            </span>
          </div>
        </div>
        <div className="flex-1 content-center">
          {fields.heading && <h4>{fields.heading}</h4>}
          {fields.body && (
            <div
              className={clsx(
                "body-small mt-3",
                layout === "50-50" ? "md:mt-6" : "md:mt-4"
              )}
            >
              {fields.body}
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      className={clsx("flex flex-col gap-4", {
        "md:flex-row-reverse md:gap-6": reverse && layout === "50-50",
        "md:flex-row md:gap-6": !reverse && layout === "50-50",
      })}
    >
      <div className="flex-1">
        <WistiaPlayer
          id={`wistia-player-${hashedId}`}
          ref={wistiaPlayerPopup}
          mediaId={hashedId}
          wistiaPopover={true}
          popoverContent="html"
          popoverOverlayOpacity={0.9}
          popoverPreventScroll={true}
          bigPlayButton={false}
          onPause={handlePause}
          onPlay={handlePlay}
          onApiReady={handleReady}
        />
        <div className="relative">
          <img src={adjustedThumbnailUrl} alt={fields.heading} />
          <button
            onClick={handleClick}
            className={clsx(
              "absolute top-1/2 left-1/2 z-[1] flex h-10 w-10 -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-full bg-white text-black md:h-16 md:w-16"
            )}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="12"
              fill="none"
            >
              <path
                d="M.864 1.202v9.6c0 .42.47.67.82.43l7.22-4.89c.31-.21.31-.67 0-.87L1.684.762a.522.522 0 0 0-.81.44h-.01Z"
                fill="#000"
              />
            </svg>
            <span className="sr-only">play button</span>
          </button>
          <span className="body-small absolute right-2 bottom-2 z-[1] rounded-md bg-black/60 px-2 py-1 text-white md:right-3 md:bottom-3">
            {timestamp}
          </span>
        </div>
      </div>

      <div className="flex-1 content-center">
        {fields.heading && <h4>{fields.heading}</h4>}
        {fields.body && (
          <div
            className={clsx(
              "body-small mt-3",
              layout === "50-50" ? "md:mt-6" : "md:mt-4"
            )}
          >
            {fields.body}
          </div>
        )}
      </div>
    </div>
  );
}
