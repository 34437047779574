import { Hit as AlgoliaHit, Hit } from "instantsearch.js/es/types";
import { useEffect, useState } from "react";
import { ALGOLIA_INDICES } from "~/config";
import { algoliaClient } from "./../../components/search/AlgoliaProvider";

type UseRelatedInsightsReturn = {
  data: AlgoliaHit[] | null;
  loading: boolean;
  error: Error | null;
};

/**
 * Custom React Hook to fetch related insights using Algolia's search method.
 * Returns insights that have at least one matching tag slug from the provided tags.
 * @param tagSlugs Tag slugs to filter insights.
 * @param currentObjectID ID of the current insight to exclude from results.
 * @param opts Optional options (e.g., disable fetching).
 * @returns An object containing the data, loading state, and error if any.
 */
export function useRelatedInsights(
  tagSlugs: string[],
  currentObjectID: string,
  opts?: { disabled?: boolean }
): UseRelatedInsightsReturn {
  const [data, setData] = useState<Hit<AlgoliaHit>[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (opts?.disabled || tagSlugs.length === 0) {
      setData(null);
      setLoading(false);
      return;
    }

    const fetchRelatedInsights = async () => {
      setLoading(true);
      try {
        const now = Date.now(); // Current time in milliseconds
        const eighteenMonthsAgo = now - 18 * 30 * 24 * 60 * 60 * 1000; // 18 months in milliseconds

        const tagFilters = tagSlugs
          .map((slug) => `tags.slug:"${slug}"`)
          .join(" OR "); //tag filters only
        const filters = `(${tagFilters}) AND NOT contentfulId:${currentObjectID} AND timestamp >= ${eighteenMonthsAgo}`; //concatenate all filters

        const response = await algoliaClient
          .initIndex(ALGOLIA_INDICES.all)
          .search<AlgoliaHit>("", {
            filters: filters.trim(), // Ensures no extra spaces or newlines that will break the filter query
            hitsPerPage: 3,
          });

        setData(response.hits.length > 0 ? response.hits : null);
      } catch (error) {
        console.error("Error fetching related insights:", error);
        setError(error as Error);
      } finally {
        setLoading(false);
      }
    };

    fetchRelatedInsights();
  }, [tagSlugs, opts, currentObjectID]);

  return { data, loading, error };
}
