import React, { ReactNode } from "react";

type TableRowProps = {
  children: ReactNode;
};

export default function TableRow({ children }: TableRowProps) {
  const childrenArray = React.Children.toArray(children);

  // Check if every child has a `header` prop set to true
  const isHeaderRow = childrenArray.every(
    (child: any) => child?.props?.header === true
  );

  return (
    <tr
      role="row"
      className={isHeaderRow ? "" : "border-b border-solid border-black"}
    >
      {children}
    </tr>
  );
}
