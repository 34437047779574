import {
  IWistiaPlayerGridFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import clsx from "clsx";
import WistiaGridCard from "../Cards/WistiaGridCard";
import {
  PageComponentData,
  useComponentData,
} from "~/contexts/ComponentDataProvider";

type WistiaPlayerGrid = {
  fields: SpecificLocaleFields<IWistiaPlayerGridFields>;
};

export default function WistiaPlayerGrid({ fields }: WistiaPlayerGrid) {
  const { page } = useComponentData() as PageComponentData;

  return (
    <section
      data-content-type="wistiaPlayerGrid"
      data-module-title={fields.moduleTitle}
      className={clsx(
        "col-span-full my-20 lg:my-10",
        page && "global-container"
      )}
    >
      <div
        className={clsx("flex flex-col gap-10 md:grid md:gap-y-10 md:gap-x-6", {
          "grid-cols-1": fields.layout === "50-50",
          "grid-cols-2": fields.layout === "Two column",
          "grid-cols-3": fields.layout === "Three column",
        })}
      >
        {fields.cards &&
          fields.cards.map((card, index) => {
            // Determine if the card's layout should be reversed
            const reverse = fields.layout === "50-50" && index % 2 === 0;

            return (
              <div key={card.sys.id}>
                <WistiaGridCard
                  fields={card.fields}
                  layout={fields.layout}
                  reverse={reverse}
                />
              </div>
            );
          })}
      </div>
    </section>
  );
}
